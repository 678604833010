import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { homepageGalleriesHolder } from './HomepageGalleries.module.scss';

const Gallery = ({ gallery, firstImage }) => {
  const imageSrc = getImage(firstImage);
  return (
    <Link to={`/gallery/${gallery.slug}`}>
      <GatsbyImage image={imageSrc} alt={gallery.title} />
      <h4>{gallery.title}</h4>
    </Link>
  );
};

export default function HomepageGalleries({ galleries, images }) {
  return (
    <ul className={homepageGalleriesHolder}>
      {galleries.map((gallery, index) => {
        const firstImageFilename = gallery.galleryRows[0][0].image;

        const galleryImages = images.filter((image) => image.relativeDirectory.includes(gallery.slug));

        const firstImage = galleryImages.find((image) => firstImageFilename.includes(image.name));

        return (
          <li key={index} className="galleryHolder">
            <Gallery gallery={gallery} firstImage={firstImage} />
          </li>
        );
      })}
    </ul>
  );
}
