import React from 'react';
import { graphql, Link } from 'gatsby';
import Seo from '../components/Seo';
import { indexHolder } from './index.module.scss';
import HomepageGalleries from '../components/HomepageGalleries';

export default function IndexPage({ data }) {
  console.log(data);

  const ayitlo = data.allGalleryDataJson.nodes.filter((gallery) => gallery.section === 'A Year In The Life Of...');
  const otherGalleries = data.allGalleryDataJson.nodes.filter((gallery) => gallery.section === 'Other Galleries');

  return (
    <article className={indexHolder}>
      <section id="ayitlo">
        <h2>A Year In The Life Of...</h2>
        <div className="galleryDescription">
          <p>
            In the books I take photos throughout the course of a year, chronicling the seasonal changes and hopefully capturing the essence and
            character of the place as seen through my eyes and lens!
          </p>
          <p>
            The images are accompanied by text containing historical titbits and natural history facts that I have come across during the course of my
            research and have particularly taken my fancy. My landscape photography has been influenced by photographer Charlie Waite, whose use of
            light I have always tried to emulate.
          </p>
        </div>
        <HomepageGalleries galleries={ayitlo} images={data.allFile.nodes} />
      </section>
      <section id="galleries">
        <h2>Other Galleries</h2>
        <div className="galleryDescription">
          <p>
            When not working on the books I am out taking photos for pleasure. I love travelling, giving me the opportunity to do street photography
            as well as my normal landscapes. Henri Cartier Bresson being a particular hero, his candid photos often with a hint of humour, are
            wonderful.
          </p>
        </div>
        <HomepageGalleries galleries={otherGalleries} images={data.allFile.nodes} />
      </section>
    </article>
  );
}

export const Head = () => <Seo />;

export const query = graphql`
  {
    allGalleryDataJson {
      nodes {
        description
        book
        title
        slug
        section
        galleryRows {
          image
        }
      }
    }
    allFile(filter: { relativeDirectory: { regex: "/^gall/" } }) {
      nodes {
        relativeDirectory
        name
        childImageSharp {
          gatsbyImageData(width: 540, height: 347, layout: CONSTRAINED)
        }
      }
    }
  }
`;
